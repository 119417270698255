<template>
  <div
    class="z-10 top-0 sticky body__header border-t border-b ticket_table_heading"
  >
    <div class="sm:items-center top-0 flex flex-wrap justify-between">
      <h3 class="text-xl leading-6 font-medium body__header--text">
        {{ this.title }}
      </h3>
      <div class="flex flex-wrap justify-center">
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <Menu as="div" class="mt-1 relative inline-block text-left">
            <div class="rounded border border-gray-300">
              <MenuButton
                class="p-2 group inline-flex justify-center text-sm font-medium capitalize body__header--button__text"
              >
                {{ $t("header.filter") }}
                <FilterIcon
                  class="flex-shrink-0 ml-1 h-5 w-5 text-gray-500 group-hover:body__header--button__text"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="rounded origin-top-left absolute mt-2 w-60 shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="type in ticketableType"
                    :key="type.name"
                    v-slot="{ active }"
                  >
                    <a
                      :class="[
                        type.active
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'body__header--menu__item' : '',
                        'rounded mx-1 block px-4 py-2 text-sm cursor-pointer',
                      ]"
                      @click="filterByType(type.name)"
                    >
                      <div class="flex justify-between">
                        {{ type.name }}
                        <!-- <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg> -->
                      </div>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="col-start-1 col-end-2 mt-1 sm:pr-10">
          <Menu as="div" class="mt-1 relative inline-block text-left">
            <div class="rounded border border-gray-300">
              <MenuButton
                class="p-2 group inline-flex justify-center text-sm font-medium capitalize body__header--button__text"
              >
                {{ $t("header.filter") }}
                <FilterIcon
                  class="flex-shrink-0 ml-1 h-5 w-5 text-gray-500 group-hover:body__header--button__text"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="rounded origin-top-left absolute mt-2 w-60 shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="type in ticketableType"
                    :key="type.name"
                    v-slot="{ active }"
                  >
                    <a
                      :class="[
                        type.active
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'body__header--menu__item' : '',
                        'rounded mx-1 block px-4 py-2 text-sm cursor-pointer',
                      ]"
                      @click="filterByType(type.name)"
                    >
                      <div class="flex justify-between">
                        {{ type.name }}
                        <!-- <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg> -->
                      </div>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div class="inline-flex">
        <div class="flex-1 ml-2 mt-3 xs:mt-0 lg:mt-0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon, SearchIcon } from "@heroicons/vue/outline";

const ticketableType = [
  { name: "App\\Ticket", active: false },
  { name: "App\\CustomerFile", active: false },
];

export default {
  props: ["title"],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    FilterIcon,
  },
  data() {
    return {
      ticketableType,
    };
  },
  methods: {
    filterByType(name) {
      for (let index = 0; index < this.ticketableType.length; index++) {
        if (this.ticketableType[index].name === name) {
          this.ticketableType[index].active = !this.ticketableType[index]
            .active;
        }
      }
    },
  },
};
</script>

<style></style>
